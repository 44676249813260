// $fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
// @import "~@fontawesome/fontawesome-free/scss/fontawesome";
// @import "~@fontawesome/fontawesome-free/scss/fa-regular";
// @import "~@fontawesome/fontawesome-free/scss/fa-solid";
// @import "~@fontawesome/fontawesome-free/scss/fa-brands";
/* You can add global styles to this file, and also import other style files */
// @import "~bootstrap/scss/bootstrap.scss";

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
